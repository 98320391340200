import { getQueryClient } from '@/api/queryClient';
import { CART_COOKIE, CART_EXPIRATION } from '@/app/consts/cart';

import { eraseCookie, getCookie, setCookie } from './cookie';

export function getCartIdCookie() {
  return getCookie(CART_COOKIE);
}

export function saveCartIdCookie(cartId: string) {
  if (!cartId) eraseCookie(CART_COOKIE);
  else setCookie(CART_COOKIE, cartId, CART_EXPIRATION);
}

/*
 * Invalidate any queries that fetch data using the user's cart.
 */
export function invalidateCartData() {
  const queryClient = getQueryClient();
  void queryClient.invalidateQueries({ queryKey: ['refreshCart'] });
  void queryClient.invalidateQueries({ queryKey: ['useProductsData'] });
  void queryClient.invalidateQueries({ queryKey: ['Calculate'] });
  void queryClient.invalidateQueries({ queryKey: ['PDPData'] });
}
