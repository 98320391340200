import type { ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers';

import isBrowser from './isBrowser';

export const SEGMENT_ANONYMOUS_ID_COOKIE = 'ajs_anonymous_id';

// Create a new cookie
export function setCookie(
  name: string,
  value: string | string[],
  minutes: number,
) {
  if (isBrowser()) {
    let expires = '';
    const date = new Date();
    const now = new Date();
    date.setTime(now.getTime() + minutes * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
    return;
  }
  return null;
}

// Get/check an existing cookie
//
export function getCookie(name: string) {
  if (isBrowser()) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]!;
      while (c.startsWith(' ')) c = c.substring(1, c.length);
      if (c.startsWith(nameEQ)) return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

// Erase the cookie
//
export function eraseCookie(name: string) {
  if (isBrowser()) {
    document.cookie = name + '=; path=/; Max-Age=-99999999;';
  }
}

// Get a cookie value from request headers
export const getServerCookie = (headers: ReadonlyHeaders, name: string) => {
  const cookie = headers.get('cookie');
  if (!cookie) return;
  const cookieDict: Record<string, string> = cookie
    .split(';')
    .reduce((acc, cur) => {
      const [key, val] = cur.split('=');
      return {
        ...acc,
        [key!.trim()]: val,
      };
    }, {});
  return cookieDict[name];
};
