import { useGeoContext } from '../contexts/GeoProvider';
import type { SupportedCurrency } from '../types/currency';

export const SYMBOL_MAP: Record<SupportedCurrency, string> = {
  AUD: '$',
  CAD: '$',
  GBP: '£',
  EUR: '€',
  JPY: '¥',
  USD: '$',
};

export default function useCurrency() {
  const { countryData } = useGeoContext();
  const currencyCode = countryData?.currency as SupportedCurrency;
  const currencySymbol = SYMBOL_MAP[currencyCode];

  return {
    countryData,
    currencyCode,
    currencySymbol,
  };
}
