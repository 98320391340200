/* eslint @typescript-eslint/prefer-nullish-coalescing: 1 -- Do not want to break existing code by switching to nullish coalesce blidnly */
import isBrowser from '@/utils/isBrowser';

import { LEGACY_ENV_KEY } from '.';

export const getLegacyEnvVariable = (key: string) => {
  // Always return the value from process.env if it exists
  // eslint-disable-next-line no-process-env -- We safely check process.env here
  if (process.env[key]) return process.env[key];
  if (isBrowser()) {
    const envMap = window[LEGACY_ENV_KEY] ?? {};
    return envMap[key];
  }
  return undefined;
};
