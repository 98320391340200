import { RingModelEnum } from '../enums/RingModelEnum';

export const BUSINESS_PATHNAME = '/business';
export const FITNESS_PATHNAME = '/fitness';
export const GIFT_PATHNAME = '/gift';
export const POP_PATHNAME = '/product/rings';
export const WHY_OURA_PATHNAME = '/why-oura';
export const MEMBERSHIP = '/membership';
export const ACCESSORIES_ROOT_PATH = '/product/accessories';
export const GEN3_POP_PATHNAME = `${POP_PATHNAME}/${RingModelEnum.Gen3}`;
