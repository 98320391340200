'use client';

import { useEnvContext } from 'next-runtime-env';
import { type ProcessEnv } from 'next-runtime-env/build/typings/process-env';
import type { ReactNode } from 'react';

import isBrowser from '@/utils/isBrowser';

export const LEGACY_ENV_KEY = '__DO_NOT_USE__ENV';

declare global {
  interface Window {
    [LEGACY_ENV_KEY]?: ProcessEnv;
  }
}

/**
 * This project uses next-runtime-env to handle dynamic client-side env variable access
 * to allow a single next build to be deployed to every environment
 *
 * Client-side env variables will need to be accessed using `useEnvContext` from now on
 *
 * This component provides us a way to use these env variables without needing to rewrite
 * a bunch of code.  This can be removed when all client-side `process.env.NEXT_PUBLIC_XXXX`
 * uses are migrated
 *
 * Usage:
 *
 * instead of process.env.NEXT_PUBLIC
 * */

const LegacyEnvVariables = ({ children }: { children: ReactNode }) => {
  const env = useEnvContext();
  if (isBrowser()) {
    window[LEGACY_ENV_KEY] = env;
  }
  return children;
};

export default LegacyEnvVariables;
