'use client';

import { useLocale } from 'next-intl';
import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

import { useSellToCountry } from '@/queries/Countries';
import type { Countries_content_countries } from '@/queries/types/Countries';

export interface GeoProviderProps extends PropsWithChildren {
  readonly countryCode: string;
}

interface GeoContext {
  countryData:
    | Countries_content_countries
    | { countryCode: string; currency: string; eu: boolean }
    | undefined;
  locale: string;
}

const Context = createContext<GeoContext | null>(null);

export const useGeoContext = () => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('Did you forget to include GeoProvider in your tree?');
  }
  return ctx;
};

const GeoProvider = ({
  children,
  countryCode,
}: GeoProviderProps): JSX.Element => {
  const locale = useLocale();
  const { data, isSuccess } = useSellToCountry(countryCode);
  const { data: usData, isSuccess: isUSSuccess } = useSellToCountry('US');

  const countries = data?.countries;
  const usCountries = usData?.countries;

  const countryData =
    // eslint-disable-next-line no-nested-ternary
    isSuccess && countries
      ? countries[0]
      : isUSSuccess && usCountries
        ? usCountries[0]
        : { countryCode: 'US', currency: 'USD', eu: false };

  return (
    <Context.Provider value={{ countryData: countryData!, locale }}>
      {children}
    </Context.Provider>
  );
};

export default GeoProvider;
