import ImgixClient from '@imgix/js-core';
import type { ImageLoader } from 'next/image';

import { getLegacyEnvVariable } from '@/app/components/LegacyEnvVariables/util';

const DEFAULT_PARAMS = {
  // Sets the format to the most optimal one for the current user
  auto: 'format',
  // Allows resizing without cropping/distorting the image or increasing the size past its native dimensions
  fit: 'max',
  // Fallback format for browsers that don't support modern formats like WebP and AVIF
  fm: 'png',
};

const imgixLoader: ImageLoader = ({ src, quality = 70, width }) => {
  const imgixClient = new ImgixClient({
    domain: getLegacyEnvVariable('NEXT_PUBLIC_IMGIX_HEALTH_DOMAIN')!,
    secureURLToken: getLegacyEnvVariable('NEXT_PUBLIC_IMGIX_HEALTH_URL_TOKEN')!,
  });
  const [path, paramString] = src.split('?');
  const params = Object.fromEntries(new URLSearchParams(paramString));

  if (!path) {
    throw new Error('Missing path in src');
  }

  return imgixClient.buildURL(path, {
    ...DEFAULT_PARAMS,
    ...params,
    q: quality.toString(),
    w: width.toString(),
  });
};

export default imgixLoader;
