import ImgixClient from '@imgix/js-core';

import { getLegacyEnvVariable } from '@/app/components/LegacyEnvVariables/util';

import type { GetImgixSrcParams } from '../types';
import getSrcUrl from './getSrcUrl';

const getImgixSrc = ({
  src,
  width,
  quality = 75,
  cdnOptions = {},
}: GetImgixSrcParams): string => {
  const imgixClient = new ImgixClient({
    domain: getLegacyEnvVariable('NEXT_PUBLIC_IMGIX_HEALTH_DOMAIN')!,
    secureURLToken: getLegacyEnvVariable('NEXT_PUBLIC_IMGIX_HEALTH_URL_TOKEN')!,
  });
  const srcUrl = getSrcUrl(src);

  return imgixClient.buildURL(srcUrl, {
    fm: 'webp',
    w: width,
    q: quality,
    ...cdnOptions,
  });
};

export default getImgixSrc;
