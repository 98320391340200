import { IMAGE_FORMATS, S3_PREFIX } from '../const';
import type { ImageFormat } from '../types';

/**
 * getSrcUrl constructs an image source url from the file name
 * (and an optional format).
 *
 * It will additionally strip the src URL of common prefixes used in this codebase
 * that will not work with the updated IMGIX source.
 *
 * URLs in IMGIX should be constructed relative to the root directory of our
 * S3 bucket, without a leading backslash.
 *
 * For example:
 *   product/my-image.jpg
 *   home-page/my-other-image.png
 */

const getSrcUrl = (src: string, defaultFormat: ImageFormat = 'jpg'): string => {
  if (!src) return '';

  // Some of our data uses absolute url's (remove the AWS location)
  const relativePath =
    typeof src === 'string' && src.startsWith(S3_PREFIX)
      ? src.substring(S3_PREFIX.length)
      : src;

  // remove the leading slash
  const path = relativePath.startsWith('/')
    ? relativePath.substring(1)
    : relativePath;

  // ensure we have the file format extension
  const includesFormat = IMAGE_FORMATS.some((format) =>
    path.includes(`.${format}`),
  );

  return includesFormat ? path : `${path}.${defaultFormat}`;
};

export default getSrcUrl;
