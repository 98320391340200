'use client';

import { useCallback, useEffect, useState } from 'react';

import isBrowser from '@/utils/isBrowser';

const usePageView = (pageName: string): void => {
  const [pageLoaded, setPageLoaded] = useState(false);

  const pageViewed = useCallback(async () => {
    if (isBrowser()) {
      await window.ouraAnalytics.page(pageName);
    }
  }, [pageName]);

  // handles page views on Component change/browser refresh only
  useEffect(() => {
    if (pageName && !pageLoaded) {
      void pageViewed();
    }
    setPageLoaded(true);
  }, [pageName, pageLoaded, pageViewed]);
};

export default usePageView;
