export const S3_PREFIX = 'https://s3.amazonaws.com/ouraring.com/images';

export const IMAGE_FORMATS = [
  'jpg',
  'jpeg',
  'gif',
  'png',
  'svg',
  'webp',
] as const;
